<template>
  <div id="home">
    <tmpl_header :showClassify="true"></tmpl_header>

    <section class="functions">
      <div class="topswiper">
        <ul>
          <li style="background: #0d25a4">
            <a href=""><img src="@/assets/demo/banner.png" /></a>
          </li>
          <li style="background: #0d25a4">
            <a href=""><img src="@/assets/demo/banner.png" /></a>
          </li>
          <li style="background: #0d25a4">
            <a href=""><img src="@/assets/demo/banner.png" /></a>
          </li>
        </ul>
      </div>

      <div class="container">
        <div class="sidebar">
          <div class="card user" v-if="!username">
            <img class="avatar" src="@/assets/demo/user.png" />
            <p>Hi, 欢迎来到云方商城</p>
            <div class="btns">
              <router-link class="login gold" to="/signin">登 录</router-link>
              <router-link class="register" to="/signup">注 册</router-link>
            </div>
          </div>
          <div class="card user" v-if="username">
            <img class="avatar" src="@/assets/demo/user.png" />
            <p>Hi, {{ username }}</p>
            <div class="btns">
              <router-link class="center gold" to="/center"
                >个人中心</router-link
              >
              <router-link class="orders" to="/center/orders"
                >我的订单</router-link
              >
            </div>
          </div>
          <div class="card notice">
            <h4><a href="">商城公告</a></h4>
            <ul>
              <li class="new"><a href="">官方商城隆重上线</a></li>
              <li><a href="">这是一段公告标题文本</a></li>
              <li><a href="">这是一段公告标题文本</a></li>
              <li><a href="">这是一段公告标题文本</a></li>
            </ul>
          </div>
          <div class="card bar">
            <a href="">
              <img src="@/assets/demo/sidebar.png" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="recommend">
      <div class="container">
        <h2>热门推荐</h2>
        <ul class="clear">
          <li v-for="pro in hotSale" :key="pro.goodsproductId">
            <router-link class="pro" :to="`/goods/view/${pro.goodsproductId}`">
              <img :src="pro.goodsproductImgsrc" />
              <strong>{{ pro.goodsproductName }}</strong>
              <p>{{ pro.goodsproductPrice }}</p>
            </router-link>
          </li>
        </ul>
        <a class="refresh" href="javascript:;" @click="loadHotSale">换一批</a>
      </div>
    </section>

    <section class="floors" v-for="floor in floors" :key="floor.floorId">
      <div class="container clear">
        <h2>{{ floor.storeyName }}</h2>
        <div class="tags">
          <a
            v-for="tag in floor.indexGeneralAdverList"
            :key="tag.channelAdverId"
            :href="tag.adverHref"
            >{{ tag.adverName }}</a
          >
        </div>
        <div class="side">
          <a v-if="floor.storeyImgHref" :href="floor.storeyImgHref">
            <img :src="floor.storeyImg" />
          </a>
          <img v-if="!floor.storeyImgHref" :src="floor.storeyImg" />
        </div>
        <ul class="clear">
          <li v-for="goods in floor.goodsList" :key="goods.goods_id">
            <router-link class="pro" :to="`/goods/view/${goods.goods_id}`">
              <img :src="goods.goods_info_img_id" />
              <strong>{{ goods.goods_info_name }}</strong>
              <p>{{ goods.goods_info_prefer_price }}</p>
            </router-link>
          </li>
        </ul>
        <a class="more" href="">全部</a>
      </div>
    </section>

    <tmpl_footer></tmpl_footer>

    <div class="fixbtns">
      <a class="favor" @click="favorSite">收藏</a>
      <a class="service" href="javascript:;"
        >在线客服<img src="../../assets/img/serv_qrcode.png"
      /></a>
      <a class="top" href="#"></a>
    </div>
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: true,
      hotSale: [],
      floors: [],
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    api.all([this.loadHotSale(), this.loadFloors()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadHotSale: function () {
      return api.post(apis.home.hotSale, {}).then((res) => {
        this.hotSale = res;
      });
    },

    loadFloors: function () {
      return api.post(apis.home.floors, {}).then((res) => {
        this.floors = res.floorList;
      });
    },

    favorSite: function () {
      try {
        const title = document.title;
        const siteUrl = location.protocol + "//" + location.hostname;
        const ua = navigator.userAgent.toLowerCase();
        //判断浏览器是否支持document.all
        if (ua.indexOf("msie 8") > -1) {
          window.external.AddToFavoritesBar(siteUrl, title); //IE8
        } else if (document.all) {
          //IE类浏览器
          try {
            window.external.addFavorite(siteUrl, title);
          } catch (e) {
            store.dispatch("toast", "请使用Ctrl+D快捷键收藏网址!");
          }
        } else if (window.sidebar) {
          //firfox等浏览器；
          window.sidebar.addPanel(title, siteUrl, "");
        } else {
          store.dispatch("toast", "请使用Ctrl+D快捷键收藏网址!");
        }
      } catch (e) {
        store.dispatch("toast", "请使用Ctrl+D快捷键收藏网址!");
      }
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./index.less");
</style>
